import React, { useEffect, useState } from 'react';
import marked from 'marked';
import ReactMarkdown from 'react-markdown'
import hljs from 'highlight.js';

hljs.highlightAll();

export const Blog : React.FC = ()=> {
    const [md, setMd] = useState('')
    useEffect(()=>{
        fetch('/test.md').then((r) => r.text()).then(md=> {
            setMd(md)
        } )

    }, []);
return <>
<p>Blog</p>
<div dangerouslySetInnerHTML={{ __html: marked(md)}} />
<ReactMarkdown>{md}</ReactMarkdown>
{/** 
 * Include posts from medium: 
 * https://medium.datadriveninvestor.com/embed-medium-as-a-blog-on-your-site-54a1b49cbe16 
 * */
}
</>;
}