import React, { useState } from 'react';

const talks = [
    { title: 'OpenAI PR reviewer with github app', url:'https://www.youtube.com/embed/h3y_tGlktj8'},
    { title: 'Arquitecturas de Web APIs', url:'https://youtube.com/embed/zs7xZKZ8dZY'},
    { title: 'Data Driven DevOps', url: 'https://youtube.com/embed/nhRdlzudqQU'},
    { title: 'Implementar un sistema de diseño multi-marca con web components', url: 'https://youtube.com/embed/vdWwvxwPKz0'},
    { title: 'SSR con TypeScript, React y Nextjs', url:'https://youtube.com/embed/YD385L-YHl0' },
    { title: 'Las reglas del juego', url: 'https://youtube.com/embed/5Elry8PepCA'},
    { title: 'Cómo desarrollo con React', url: 'https://youtube.com/embed/VH6mTS2Bba4'},
    { title: 'Swagger CodeGen - Cuando front encontró a back', url: 'https://youtube.com/embed/TkXJ2fM97RE'},
    { title: 'Arquitectura Angular para 100 aplicaciones web', url: 'https://youtube.com/embed/7qLG_VsD2a8'},
    { title: 'Empezando por el principio de diseño', url: 'https://youtube.com/embed/NtqK9ll6r0M'},
    { title: 'Por qué usar webpack', url: 'http://youtube.com/embed/7ZGIh-7t9hc'},
]

export const Talks = ()=> <>{talks.map(({ url }) =><Talk url={url} />)}</>


const Talk: React.FC<{ url: string }> = ({ url })=> {
const [loaded, setLoaded] = useState(false);
return <div style={{width:"350", height:"200", display: 'inline-block', margin: 5, border: '1px solid white'}} > 
    { !loaded && 'Loading...'}
    <iframe 
        key={url}
        style={{ visibility: loaded ? 'visible': 'hidden'}}
        src={url} 
        onLoad={()=> setLoaded(true)}
    >
    </iframe> 
</div>;
}