import React from 'react';
import photo from './foto.webp';
import styled, { createGlobalStyle } from 'styled-components';


export const WhoIAm: React.FC = ()=> (<div style={{height:'calc(100% - 100px)', padding: '100px'}}>
    <small>Who I am</small>
    <h1 style={{color: "rgb(88, 255, 244)"}}>Carlos Bastos</h1>
    <h2>
    Technical Lead, Software Architect, Consultor, <br/> Speaker and Staff Software Engineer
    </h2>
    <img src={photo} style={{borderRadius: '50%', marginLeft:'425px'}}/>
    <Overlay>Software Crafter, eXtreme Programmer, Lean & Kaizen mindset</Overlay>

</div>)

const Overlay = styled.div`
    height: 100px;
    width: 500px;
    line-height: 100px;
    position:relative;
    bottom: 200px;
    background-color: rgb(88, 255, 244);
    text-align: right;
    vertical-align:middle;
    color: black;
    padding-right: 20px;
`;