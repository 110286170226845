import React from 'react';
import { BrowserRouter as Router, Switch, Route, NavLink, Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Logo } from './Logo';
import { Talks } from './Talks';
import { WhoIAm } from './WhoIAm';
import { Blog } from './Blog';

const Layout = styled.div`
  background-color: #2a2a2a;
  width: 100%;
  height: 100%;
  color: #ffffff;
`;

const ResetStyles = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }
  * { 
    margin: 0;
    padding: 0;
    border: 0;
  }
`

const TopBar = styled.div`
  display: flex;
  width: calc(100% - 150px);;
  height: 100px; 
  display: flex;
  align-items: center;
  padding-left: 100px; 
  padding-right: 50px; 
  
  nav {
    margin-left:auto;
  }

  li { 
    display:inline;
    margin-right: 30px;
    a { 
      color: white;
      text-decoration: none;
    }
    .is-active {
      color: rgb(88, 255, 244);
    }
  }
`;

const Content = styled.div`
  width: calc(100% - 200px);
  padding-left: 100px;
  padding-right: 100px;
`;

export const App: React.FC = () => {
  return (
    <Router>
      <ResetStyles />
      <Layout>
        <TopBar>
          <Link to="/">
            <Logo />
          </Link>
          <nav>
            <ul>
              <li>
                <NavLink to="/" exact activeClassName="is-active">Who I am</NavLink>
              </li>
              <li>
                <NavLink to="/talks" exact activeClassName="is-active">Talks</NavLink>
              </li>
              {/*<li>
                <NavLink to="/blog" exact activeClassName="is-active">Blog</NavLink>
              </li>*/}
            </ul>
        </nav>
        </TopBar>
        <Content>
          <Switch>
            <Route path="/talks">
            <Talks />
            </Route>
            <Route path="/blog">
              <Blog />
            </Route>
            <Route path="/">
              <WhoIAm />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </Router>
  );
}